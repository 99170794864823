/* eslint-disable no-undef */
import React from 'react'
import wpp from '../../images/icons/wpp.svg'

import './wpp.scss'

const Wpp = () => {
  return (
    <>
    <div className='floatWpp'>
      <a href={`https://api.whatsapp.com/send?phone=5511943147788`} target='_blank' rel="noreferrer">
        <img src={wpp} />
      </a>
    </div>
    </>
  )
}

export default Wpp
